import React, {Component} from 'react';
import {Helmet} from 'react-helmet-async';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {connect} from 'react-redux';
import {reduxForm, Field, getFormValues, reducer as formReducer} from 'redux-form';
import {email as validateEmail} from 'goodeggs-validators';

import MarketLayout from 'web/components/market_layout';
import Alert from 'web/components/alert';
import SubmitButton from 'web/components/submit_button';

import {actions as ResetPasswordPageActions, reducer as resetPasswordPageReducer} from './duck';

const inputWithError = ({input, meta, type, placeholder, className, inputClassName}) => {
  const inputClasses = classNames(
    `form-control reset-password-page__form-row-input ${inputClassName}`,
    {
      invalid: meta.touched && meta.error && !meta.active,
      valid: meta.touched && !meta.error,

      '': !meta.touched && !meta.error,
    },
  );
  return (
    <div className={className}>
      <input className={inputClasses} {...input} placeholder={placeholder} type={type || 'text'} />
      {inputClasses.indexOf('invalid') !== -1 ? (
        <div className="reset-password-page__email_error error">{meta.error}</div>
      ) : null}
    </div>
  );
};

inputWithError.propTypes = {
  input: PropTypes.object,
  meta: PropTypes.object,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  inputClassName: PropTypes.string,
};

const ResetPasswordForm = ({valid, pristine, submitting, handleSubmit}) => (
  <form className="reset-password-page__form" onSubmit={handleSubmit}>
    <Field
      name="email"
      type="email"
      className="reset-password-page__form-row"
      inputClassName="reset-password-page__form-row-input-email-address"
      placeholder="Email address"
      component={inputWithError}
    />
    <SubmitButton
      className="reset-password-page__form-submit"
      isSaving={submitting}
      disabled={!valid || submitting || pristine}
      workingLabel="Send"
    >
      Send
    </SubmitButton>
  </form>
);

ResetPasswordForm.propTypes = {
  pristine: PropTypes.bool,
  submitting: PropTypes.bool,
  handleSubmit: PropTypes.func,
};

class ResetPasswordPage extends Component {
  constructor() {
    super();
    this.state = {};
  }

  onSubmit() {
    const resetPasswordData = getFormValues('resetPassword')(this.props);
    return this.props.submit({
      emailAddress: resetPasswordData.email,
      returnTo: this.props.returnTo,
    });
  }

  render() {
    return (
      <MarketLayout disableUpcomingOrdersBanner disableBasketDropdown>
        <Helmet>
          <title>Forgot Password | Good Eggs</title>
        </Helmet>

        <div className="reset-password-page narrow-page">
          {!this.props.success ? (
            <div>
              <h1 className="reset-password-page__title">Forgot password?</h1>

              <p className="reset-password-page__message">
                We’ll send you an email to help you reset it.
              </p>

              <div className="reset-password-page__container">
                {this.props.error ? (
                  <Alert
                    className="reset-password-page__alert"
                    type="error"
                    heading="Sorry, there was a problem resetting your password."
                  >
                    Error resetting password, please contact community care
                  </Alert>
                ) : null}
                <ResetPasswordConnectedForm onSubmit={this.onSubmit.bind(this)} />
              </div>
            </div>
          ) : (
            <div className="reset-password-page__success">
              <h1 className="reset-password-page__title">Check your email</h1>

              <div className="reset-password-page__message">
                We’ve sent you an email to help you reset your password if that account exists.
              </div>
            </div>
          )}
        </div>
      </MarketLayout>
    );
  }
}

ResetPasswordPage.propTypes = {
  submit: PropTypes.func.isRequired,
  signInUrl: PropTypes.string.isRequired,
  error: PropTypes.object,
  success: PropTypes.bool,
  returnTo: PropTypes.string,
};

const validateResetPasswordForm = (values) => {
  const errors = {};

  if (!validateEmail(values.email)) {
    errors.email = "That email address doesn't look quite right";
  }

  return errors;
};

const ResetPasswordReduxForm = reduxForm({
  form: 'resetPassword',
  validate: validateResetPasswordForm,
})(ResetPasswordForm);

const ResetPasswordConnectedForm = connect(() => ({}))(ResetPasswordReduxForm);

ResetPasswordPage.reducer = function (state, action) {
  const newState = MarketLayout.reducer(state, action);
  const resetPasswordPage = resetPasswordPageReducer(newState.resetPasswordPage, action);
  const form = formReducer(newState.form, action);
  return {...newState, resetPasswordPage, form};
};

function mapStateToProps(state) {
  return {...state.resetPasswordPage, form: state.form};
}

const mapDispatchToProps = {
  submit: ResetPasswordPageActions.submit,
};

ResetPasswordPage.pageName = 'Update Password';

export default connect(mapStateToProps, mapDispatchToProps)(ResetPasswordPage);
