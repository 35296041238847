import gql from 'graphql-tag';
import {SubmissionError} from 'redux-form';

import client, {sanitizeGraphQLError} from 'web/helpers/graphql_client';

export const ERROR_SUBMITTING = 'RESET_PASSWORD_ERROR_SUBMITTING';
export const SUCCESS_SUBMITTING = 'RESET_PASSWORD_SUCCESS_SUBMITTING';

export const actions = {
  submit:
    ({emailAddress, returnTo}) =>
    async (dispatch) => {
      try {
        const response = await resetPassword({email: emailAddress, returnTo});

        dispatch({type: SUCCESS_SUBMITTING});

        return response;
      } catch (e) {
        sanitizeGraphQLError(e);

        if (e.graphQLErrors && e.graphQLErrors[0].extensions.code === 'BAD_USER_INPUT') {
          throw new SubmissionError({emailAddress: e.message});
        }

        dispatch(actions.errorSubmitting(e.message));
      }
    },
  errorSubmitting: (error) => ({
    value: error,
    type: ERROR_SUBMITTING,
  }),
};

const resetPassword = ({email, returnTo}) =>
  client.mutate({
    mutation: gql`
      mutation ResetPassword($input: ResetPasswordInput!) {
        resetPassword(input: $input)
      }
    `,
    variables: {
      input: {email, returnTo},
    },
  });

export function reducer(state = {}, action = {}) {
  switch (action.type) {
    case ERROR_SUBMITTING:
      return {...state, error: action.value};
    case SUCCESS_SUBMITTING:
      return {...state, success: true};
    default:
      return state;
  }
}
